import React, { FC, useRef, useImperativeHandle } from "react";
import {
  ValidatorComponentProps,
  TextValidator,
  SelectValidator
} from "react-material-ui-form-validator";
import RHDropdownIcon from "icon-dropdown";
import { FormHelperText } from "utils/material-ui-core";
import { useInitialState } from "hooks-use-initial-state";
import { FormHelperText as TailwindFormHelperText } from "@RHCommerceDev/component-tailwind-textfield";
import { useEnv } from "hooks/useEnv";
import yn from "yn";

export type BlurValidatorProps = ValidatorComponentProps & {
  errors?: string | boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

export const BlurTextValidator: FC<BlurValidatorProps> = React.forwardRef(
  ({ onBlur, ...props }, ref) => {
    const [value, setValue] = useInitialState<string>(props?.value || "");
    const validatorRef = useRef<TextValidator>(null);
    useImperativeHandle(ref, () => ({
      validate: () => (validatorRef as any).current?.validate(value.trim())
    }));

    const handleBlurValidate = (event: React.FocusEvent<HTMLInputElement>) => {
      (validatorRef as any).current?.validate(value.trim(), true);
      if (onBlur) onBlur(event);
    };

    return (
      <TextValidator
        {...props}
        onChange={handleChange}
        value={value}
        onBlur={handleBlurValidate}
        ref={validatorRef}
        onKeyDown={e => {
          e.stopPropagation();
        }}
      >
        {props.children}
      </TextValidator>
    );

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      setValue(event.target.value);
      props.onChange?.(event);
    }
  }
);

export const BlurSelectValidator: FC<BlurValidatorProps> = React.forwardRef(
  (props, ref) => {
    const [value, setValue] = useInitialState<string>(props?.value || "");
    const validatorRef = useRef<SelectValidator>(null);
    useImperativeHandle(ref, () => ({
      validate: () => (validatorRef as any).current?.validate(value),
      setValue: (value: any) => setValue(value)
    }));

    const handleBlurValidate = (event: React.FocusEvent<HTMLInputElement>) => {
      (validatorRef as any).current?.validate(value, true);
    };

    const env = useEnv();
    const FEATURE_TAILWIND_TEXTFIELD = yn(env.FEATURE_TAILWIND_TEXTFIELD);
    const FormHelperTextComponent = FEATURE_TAILWIND_TEXTFIELD
      ? TailwindFormHelperText
      : FormHelperText;

    return (
      <div id={"component-input-validation"}>
        <SelectValidator
          {...props}
          SelectProps={{
            IconComponent: RHDropdownIcon,
            ...props.SelectProps,
            native: true
          }}
          onChange={handleChange}
          value={value}
          onBlur={handleBlurValidate}
          ref={validatorRef}
        >
          {props.children}
        </SelectValidator>
        {props.errors && (
          <FormHelperTextComponent error={true}>
            {props.errors}
          </FormHelperTextComponent>
        )}
      </div>
    );

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      setValue(event.target.value);
      props.onChange?.(event);
    }
  }
);
